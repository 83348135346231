

/* Import San Francisco Font */
@import url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff");
/* Import Abril Fatface font */
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");
:root {
  /* Update the body-font variable to use San Francisco Display */
  --body-font: "San Francisco Display", -apple-system, BlinkMacSystemFont,
    sans-serif;
  --text-15: 0.9375rem;
  --primary50:#EAEEFA;
  --primary100:#D6DDF5;
  --primary200:#ADBBEB;
  --primary400:#5B78D7;
  --primary300:#849AE1;
  --primary500:#2743A0;
  --primary600:#2845A4;
  --primary700:#1E347B;
  --black:#000000;
  --white:#ffffff;
  --neutral20:#F8F8F8;
  --neutral50:#F2F2F3;
  --neutral100:#E5E6E6;
  --neutral200:#CACCCE;
  --neutral300:#B0B3B5;
  --neutral400:#96999C;
  --neutral600:#636669;
  --neutral800:#313335;
  --warning50:#FDF9E8;
  --warning100:#FBF3D0;
  --warning200:#F7E7A1;
  --warning300:#F3DB72;
  --warning400:#EECF44;
  --warning500:#F7E8A4;
  --warning600:#BB9C11;
  --orange50:#FFEFE6;
  --orange100:#FFE0CC;
  --orange200:#FEC09A;
  --orange300:#FEA167;
  --orange400:#FD8235;
  --orange600:#CA4F02;
  --secondary50:#FCE9EA;
  --secondary100:#F8D3D5;
  --secondary200:#F2A6AB;
  --secondary300:#EB7A81;
  --secondary400: #E54D57;
  --secondary600:#B21A24;
  --success50:#EFF7EE;
  --success100:#DEEEDD;
  --success200:#BEDEBA;
  --success300:#9DCD98;
  --success400:#7DBC76;
  --success600:#4A8943;
  --complementary50:#E7F6FE;
  --complementary100:#CEECFD;
  --complementary300:#6DC7F8;
  --complementary400:#3CB5F6;
  --complementary600:#0982C3;
  --complementary800:#054161;
  --FontW400:400;
  --FontW500:500;
  --FontW510:510;
  --FontW590:590;
  --FontW600:600;
  --FontW700:700;
  --FontW800:800;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--body-font);
}

.MuiTab-root {
  text-transform: none !important;
}

.max-container {
  max-width: 1440px;
}

body {
  scrollbar-color: rgb(173, 191, 243) rgb(228, 235, 255);
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  background-color: #fff;
  width: 3px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background-color: rgb(228, 235, 255);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(173, 191, 243);
  border-radius: 16px;
  border: 4px solid rgb(173, 191, 243);
}

::-webkit-scrollbar-button {
  display: none;
}

.MuiAutocomplete-root {
  width: 100%;
}

.MuiTabs-indicator {
  background-color: #1d3679 !important;
}

/* Added By Kamal Rajput */
button.Btn{border-radius: 8px;display: inline-block; padding: 10px 20px;align-items: center;gap: 10px;text-decoration: none;font-weight: 700;font-size: 18px;}
button.Btn svg{height: 32px; width: 32px; margin-left: 8px; border: solid 1px #fff;border-radius: 50%;}

button.Btn.sm{border-radius: 8px;display: inline-block; padding: 8px 16px;align-items: center;gap: 8px;text-decoration: none;font-weight: 400;font-size: 16px;}
button.Btn.sm svg{height: 28px; width: 28px; margin-left: 6px; border: solid 1px #fff;border-radius: 50%;}

.colWidth-5{width: calc(calc(100% - 64px) / 5);}
.colWidth-4{width: calc(calc(100% - 48px) / 4);}
.colWidth-3{width: calc(calc(100% - 32px) / 3);}
.colWidth-3m1{width: calc(calc(100% - 32px) / 3);}
.colWidth-3m1V1{width: calc(calc(100% - 64px) / 3);}
.colWidth-2{width: calc(calc(100% - 16px) / 2);}
.colWidthD1M2{width: calc(calc(100% - 0px) / 1);}


.success400Bg{background: var(--success400) !important;}
.success50Bg{background: var(--success50) !important;}
.complementary50Bg{background: var(--complementary50) !important;}
.complementary400Bg{background: var(--complementary400) !important;}
.neutral20Bg{background: var(--neutral20) !important;}
.primary500Bg{background: var(--primary500) !important;}
.primary50Bg{background: var(--primary50) !important;}
.whiteBg{background: #ffffff !important;}
.blackBg{background: var(--black) !important;}

.primaryBg50{background: var(--primary50) !important;}
.orangeBg50{background: var(--orange50)!important;}
.successBg50{background: var(--success50)!important;}
.warningBg50{background: var(--warning50)!important;}
.secondaryBg50{background: var(--secondary50)!important;}

.primaryBrd300{border:1px solid var(--primary300) !important;}
.orangeBrd300{border:1px solid var(--orange300) !important;}
.successBrd300{border:1px solid var(--success300) !important;}
.warningBrd300{border:1px solid var(--warning300) !important;}
.secondaryBrd300{border:1px solid var(--secondary300) !important;}
.complementaryBrd300{border:1px solid var(--complementary300) !important;}
.neutralBrd100{border:1px solid var(--neutral100) !important;}

.Txtwhite{color: #ffffff !important;}
.Txtblack{color: #000000 !important;}
.Txtprimary500{color: var(--primary500) !important;}
.Txtprimary600{color: var(--primary600) !important;}
.Txtsecondary600{color: var(--secondary600) !important;}
.Txtorange600{color: var(--orange600) !important;}
.Txtcomply400{color:var(--complementary400) !important}
.Txtcomply600{color:var(--complementary600) !important}
.Txtneutral600{color: var(--neutral600) !important;}
.Txtneutral800{color: var(--neutral800) !important;}
.Txtwarning600{color: var(--warning600) !important;}
.Txtsuccess600{color: var(--success600) !important;}
 
.FW500{font-weight:var(--FontW500);}
.FW510{font-weight:var(--FontW510);}
.FW500{font-weight:var(--FontW500);}
.FW590{font-weight:var(--FontW590);}
.FW700{font-weight:var(--FontW700);}

.txt22{font-size: 22px !important;line-height: 28px !important;}
.txt24{font-size: 24px !important;line-height: 28px !important;}
.txt32{font-size: 32px !important;line-height: 38px !important;}
.txt40{font-size: 40px !important;line-height: 48px !important;}

.CatMenuItems {
  height: 100%;
}
.CatMenuL1 {
  width: 15%;
  height: 100%;
}
.CatMenuL2 {
  width: 100%;
  height: 100%;
}
.CatMenuL3 {
  width: 100%;
  height: 100%;
}
.CatMenuLast {
  width: 100%;
}
.CatMenuLast li {
  width: calc(100% / 3);
}
.headingContainer{padding-bottom: 40px;color: var(--black);text-align: center;display: flex;flex-direction: column;}
.headingContainer .heading{font-size: 54px;font-weight: 590;line-height: 66px;padding-bottom: 4px;}
.headingContainer .heading b{font-size: 32px;font-weight: 700;line-height: 38px;}
.headingContainer .heading span{color: var(--primary500);}
.headingContainer .heading span.white{color: var(--white);}
.headingContainer .heading span.secondary600{color: var(--secondary600);}
.headingContainer .Subheading{font-size: 24px;font-weight: 510;line-height: 28px;color: var(--neutral600);}
.headingContainer .Subheading.white{color: var(--white);}
.headingContainer .Subheading span.success400{color: var(--success400);}
 
.insideBannerBx{position: relative;height: 595px;width: 100%;}
.insideBannerInfo{color:var(--white);position: absolute; left: 0%;top: 50%;transform: translate(0%, -50%);}
.insideBannerInfo b{font-size: 56px;font-weight: 500;line-height: 66px;display: block;margin-bottom: 28px;color: var(--warning400);}
.insideBannerInfo h1{font-size: 56px;font-weight: 500;line-height: 66px;}
.insideBannerInfo h1 span{color: var(--warning400);}
.insideBannerInfo h1 span.orange400{color: var(--orange400);}
.insideBannerInfo p{font-size: 24px;font-weight: 400;line-height: 28px;color: var(--primary50);display: block;margin-top: 28px;}
.insideBannerInfo p span{color: var(--warning400);}
.insideBannerInfo button.HeaderBtn{border-radius: 8px;background: #2743A0;display: inline-block; padding: 10px 20px;margin-top: 20px;align-items: center;gap: 10px;text-decoration: none;font-weight: 700;font-size: 16px;}
.insideBannerInfo button.HeaderBtn svg{height: 32px; width: 32px; margin-left: 8px; border: solid 1px #fff;border-radius: 50%;}
.PageInfoBoxGry{}
.PageInfoBoxGry small{font-size: 32px; font-weight: 590;line-height: 38px; color: var(--primary700);margin-bottom: 8px;}
.PageInfoBoxGry h2{font-size: 54px; font-weight: 510;line-height: 66px; color: var(--black);}
.PageInfoBoxGry p{font-size: 18px;font-style: normal;font-weight: 400;line-height: 26px;margin-bottom: 16px;}

.whiteSmallCard{display: flex; padding: 20px 15px;flex-direction: column;align-items: center; gap: 30px; border-radius: 8px;text-align: center; background: var(--white);box-shadow: 0px 0px 8px 0px rgba(39, 67, 160, 0.25);width: calc(calc(100% - 64px) / 4);  }
.whiteSmallCard img{height: 84px;}
.whiteSmallCard b{font-size: 22px;font-weight: 590;line-height: 26px;}

.whiteGrayBox{display: flex; padding: 20px 16px; flex-direction: column; gap: 12px; border-radius: 8px;border: 1px solid var(--neutral200); background: var(--white);font-size: 18px;font-weight: 400;line-height: 26px;}
.whiteGrayBox .iconBox{display: flex; padding: 10px; border-radius: 16px;border: 1px solid var(--neutral200); background: var(--neutral20);width: 82px;height: 82px;justify-content: center;align-items: center;}
.whiteGrayBox .TextBox {font-size: 16px;line-height: 20px;}
.whiteGrayBox .TextBox b{font-size: 22px; font-weight: 510; line-height: 26px;padding-bottom: 16px;display: block;}

.grayWhiteBox{display: flex; padding: 20px 16px; flex-direction: column; gap: 12px; border-radius: 8px;border: 1px solid var(--neutral200); background: var(--neutral20);font-size: 18px;font-weight: 400;line-height: 26px;}
.grayWhiteBox {font-size: 16px;line-height: 20px;}
.grayWhiteBox .iconBox{display: flex; padding: 10px; border-radius: 16px;border: 1px solid var(--neutral200); background: var(--white);width: 82px;height: 82px;justify-content: center;align-items: center;}
.grayWhiteBox .TextBox {font-size: 16px;}
.grayWhiteBox .TextBox b{font-size: 22px; font-weight: 510; line-height: 26px;padding-bottom: 16px;display: block;}

.grayWhiteBoxV1{display: flex; padding: 0px 0px 0px 0px; flex-direction: column; border-radius: 8px;border: 1px solid var(--neutral200); overflow: hidden; background: var(--neutral20);font-size: 18px;font-weight: 400;line-height: 26px;}
.grayWhiteBoxV1 b{font-size: 24px;padding:16px; background: var(--white); font-weight: 510; line-height: 26px;padding-bottom: 16px;display: block;}
.grayWhiteBoxV1 .TextBox {padding:40px 16px 20px 16px;line-height: 20px;display: -webkit-inline-box;}
.grayWhiteBoxV1 .TextBox .iconBox{display: flex;  padding: 10px; border-radius: 16px;border: 1px solid var(--neutral200); background: var(--white);width: 82px;height: 82px;float: left;}
.grayWhiteBoxV1 .TextBox .textItem {font-size: 16px;float: left;padding-left: 12px;padding-right: 80px}
.grayWhiteBoxV1 .TextBox .textItem b{font-size: 22px;line-height: 26px;font-weight: 450;background: transparent;padding: 0px;}
.grayWhiteBoxV1 .TextBox .textItem p{font-size: 16px;}

.GrayCard{border: 1px solid var(--neutral200); background: var(--neutral20);display: flex;flex-direction: column;}
.GrayCard span{font-size: 22px;font-weight: 500;color: var(--black);line-height: normal;padding: 24px 16px;}
.SmallCard{display: flex; padding: 20px 16px; flex-direction: column; gap: 12px; border-radius: 8px;font-size: 16px;font-weight: 400;line-height: 20px;}

.ZigZagSection{gap: 80px;padding: 46px 0px 90px 0px;}
.ZigZagImgBox{position: relative;}
.ZigZagImgBox img{border-radius: 20px;border: 10px solid var(--white);background: var(--white);box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.50);}
.ZigZagImgBox .ImgItem{position: absolute;z-index: -1;left: -40px;top: -40px;}
.ZigZagImgBox .ImgItemLeft{position: absolute;z-index: -1;right: -40px;top: -40px;}
.ZigZagImgBox .ImgItem img, .ZigZagImgBox .ImgItemLeft img{border-radius: 0px;border: none;background:transparent;box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.0);}
.ZigZagText{font-size: 16px; font-weight: 400; line-height: 20px; color: var(--neutral800);text-align: left;}
.ZigZagText .grayText{padding-bottom: 20px;font-size: 24px; font-weight: 510; line-height: 28px;color: var(--neutral600);}
.ZigZagText .blueBgHeading{padding:8px 16px;font-size: 28px; font-weight: 510; line-height: 34px;color: var(--warning300);background: var(--primary500);margin-bottom: 16px;display: inline-block;}
.ZigZagText .BlueText{padding-bottom: 20px;font-size: 40px; font-weight: 500; line-height: 28px;color: var(--primary700);}
.ZigZagText .heading48{font-size: 48px;font-weight: 590;line-height: 58px;color: var(--neutral800);}
.ZigZagText .heading48 span{color: var(--primary500);}
.ZigZagText .heading48 span.underLine{border-bottom:4px solid var(--warning400);}
.ZigZagText .Subhead{padding-top: 16px;display: flex; flex-direction: column;gap: 8px;}
.ZigZagText .Subhead b{font-size: 28px; font-weight: 590; line-height: 34px;color: var(--black);}
.ZigZagText .Subhead p{font-size: 16px; font-weight: 400; line-height: 20px; color: var(--neutral800);}
.ZigZagText .PointBox{padding-top: 16px;}
.ZigZagText .PointBox i{padding-top: 16px;font-size: 24px; line-height: 28px;display: block;font-weight: 500;}
.ZigZagText .PointBox sup{color: var(--secondary400);}
.ZigZagText .PointBox ul{display: flex;gap: 16px;flex-direction: column;}
.ZigZagText .PointBox ul li{display: flex;}
.ZigZagText .PointBox ul li .bulletBrd{display: flex; width: 40px; height: 40px;border-radius: 30px; border: 1px solid var(--primary500);padding: 10px;margin-right: 12px;}
.ZigZagText .PointBox ul li span{font-size: 16px; font-weight: 400; line-height: 20px; color: var(--neutral800);}
.ZigZagText .PointBox ul li span b{display: block;font-size: 22px; font-weight: 510;line-height: 26px; color: var(--black);}

.ScoreBox{color: var(--neutral20);font-size: 18px;}
.ScoreBox h3{font-size: 36px;font-weight: 600;line-height: normal;}
.ScoreBox h3 span{font-size: 54px;font-weight: 700;display: block;color: var(--complementary400);}
.ScoreBox b{}
.ScoreBox .LearnMore{border: 1px solid var(--white);color: var(--white);border-radius: 5px;padding: 10px 12px;font-size: 18px;}
.ScoreBox .ContactUs{border: 1px solid var(--neutral20);background: var(--neutral20);color: var(--neutral800);padding: 10px 12px;font-size: 18px;border-radius: 5px;}
.ScoreBox .ScoreStatusBox{}
.ScoreBox .ScoreStatusBox h4{font-size: 28px;font-weight: 600;line-height: 34px;margin-bottom: 24px;}
.ScoreBox .ScoreItem{position: relative;}
.ScoreBox .ScoreItem span{position: absolute;font-size: 44px;font-weight: 700;width: 100%;text-align: center;bottom:0%; left: 0;}

.roundZigZag{margin-bottom: -12px;}
.roundZigZag .NumberBoxRight{height: 146px; width: 146px;border:solid 12px #1387F6; border-top-left-radius:50%;border-bottom-left-radius:50%; border-right:none;display: flex;justify-content: center;align-items: center;}
.roundZigZag .NumberBoxRight span{background: var(--complementary800);height: 110px;width:110px;border-radius: 50%;text-align: center;color: var(--white);font-size: 54px; font-weight: 600;line-height: 110px;}
.roundZigZag .TextBox{font-size: 18px;font-weight: 400;line-height: 26px;}
.roundZigZag .TextBox b{font-size: 28px;font-weight: 600;line-height: 34px;}
.roundZigZag .NumberBoxLeft{height: 146px; width: 146px;border:solid 12px #1387F6; border-top-right-radius: 50%; border-bottom-right-radius: 50%;border-left: none; display: flex;justify-content: center;align-items: center;}
.roundZigZag .NumberBoxLeft span{background: #FFBB1F;height: 110px;width:110px;border-radius: 50%;text-align: center;color: var(--black);font-size: 54px; font-weight: 600;line-height: 110px;} 

.DiscoverFeatures{padding: 20px; border-radius: 8px;display: flex;padding-right: 0px;position: relative;min-height: 500px;}
.DiscoverFeatures .HeadingBox{display: flex;flex-direction: column; justify-content: space-between; width:42%;padding-right: 10px;}
.DiscoverFeatures .HeadingBox h4{font-size: 32px;font-weight: 500;line-height: 38px; }
.DiscoverFeatures .HeadingBox h5{font-size: 150px;font-weight: 860;line-height: 120px;}
.DiscoverFeatures .PointsBox{width: 66%; position: absolute;right: 0;}
.DiscoverFeatures .PointsBox .pointOne, .DiscoverFeatures .PointsBox .pointTwo, .DiscoverFeatures .PointsBox .pointThree{border-radius: 8px;background: var(--white);padding: 15px;font-size: 18px;font-weight: 400;line-height: 26px;color: var(--black);}
.DiscoverFeatures .PointsBox .pointOne b, .DiscoverFeatures .PointsBox .pointTwo b, .DiscoverFeatures .PointsBox .pointThree b{font-size: 22px;font-weight: 500;line-height: 26px;color: var(--black);display: flex;}
.DiscoverFeatures .PointsBox .pointOne{position:absolute;min-height: 140px;top: 20px;margin-left: 15%;right: -15px;width: 335px; display: flex;flex-direction: column;justify-content: center;}
.DiscoverFeatures .PointsBox .pointTwo{position:absolute;top: 180px;margin-right: 4%;left: -2%;min-height: 110px;width: 370px; display: flex;flex-direction: column;justify-content: center;}
.DiscoverFeatures .PointsBox .pointThree{position:absolute;top: 310px;margin-left: 15%;right: -15px;min-height: 140px;width: 335px; display: flex;flex-direction: column;justify-content: center;}
.DiscoverFeatures.secondary{background: var(--secondary50);border: solid 1px var(--secondary400);}
.DiscoverFeatures.secondary .HeadingBox h5{color: var(--secondary50); text-shadow: 1px 1px 0 var(--secondary400), -1px -1px 0 var(--secondary400), 1px -1px 0 var(--secondary400), -1px 1px 0 var(--secondary400), 1px 1px 0 var(--secondary400);}
.DiscoverFeatures.secondary .PointsBox .pointOne, .DiscoverFeatures.secondary .PointsBox .pointTwo, .DiscoverFeatures.secondary .PointsBox .pointThree{border: solid 1px var(--secondary400);}
.DiscoverFeatures.primary{background: var(--primary50);border: solid 1px var(--primary400);}
.DiscoverFeatures.primary .HeadingBox h5{color: var(--primary50); text-shadow: 1px 1px 0 var(--primary400), -1px -1px 0 var(--primary400), 1px -1px 0 var(--primary400), -1px 1px 0 var(--primary400), 1px 1px 0 var(--primary400);}
.DiscoverFeatures.primary .PointsBox .pointOne, .DiscoverFeatures.primary .PointsBox .pointTwo, .DiscoverFeatures.primary .PointsBox .pointThree{border: solid 1px var(--primary400);}
.DiscoverFeatures.warning{background: var(--warning50);border: solid 1px var(--warning400);}
.DiscoverFeatures.warning .HeadingBox h5{color: var(--warning50); text-shadow: 1px 1px 0 var(--warning400), -1px -1px 0 var(--warning400), 1px -1px 0 var(--warning400), -1px 1px 0 var(--warning400), 1px 1px 0 var(--warning400);}
.DiscoverFeatures.warning .PointsBox .pointOne, .DiscoverFeatures.warning .PointsBox .pointTwo, .DiscoverFeatures.warning .PointsBox .pointThree{border: solid 1px var(--warning400);}
.DiscoverFeatures.neutral{background: var(--neutral50);border: solid 1px var(--neutral400);}
.DiscoverFeatures.neutral .HeadingBox h5{color: var(--neutral50); text-shadow: 1px 1px 0 var(--neutral400), -1px -1px 0 var(--neutral400), 1px -1px 0 var(--neutral400), -1px 1px 0 var(--neutral400), 1px 1px 0 var(--neutral400);}
.DiscoverFeatures.neutral .PointsBox .pointOne, .DiscoverFeatures.neutral .PointsBox .pointTwo, .DiscoverFeatures.neutral .PointsBox .pointThree{border: solid 1px var(--neutral400);}
.DiscoverFeatures.success{background: var(--success50);border: solid 1px var(--success400);}
.DiscoverFeatures.success .HeadingBox h5{color: var(--success50); text-shadow: 1px 1px 0 var(--success400), -1px -1px 0 var(--success400), 1px -1px 0 var(--success400), -1px 1px 0 var(--success400), 1px 1px 0 var(--success400);}
.DiscoverFeatures.success .PointsBox .pointOne, .DiscoverFeatures.success .PointsBox .pointTwo, .DiscoverFeatures.success .PointsBox .pointThree{border: solid 1px var(--success400);}
.DiscoverFeatures.orange{background: var(--orange50);border: solid 1px var(--orange400);}
.DiscoverFeatures.orange .HeadingBox h5{color: var(--orange50); text-shadow: 1px 1px 0 var(--orange400), -1px -1px 0 var(--orange400), 1px -1px 0 var(--orange400), -1px 1px 0 var(--orange400), 1px 1px 0 var(--orange400);}
.DiscoverFeatures.orange .PointsBox .pointOne, .DiscoverFeatures.orange .PointsBox .pointTwo, .DiscoverFeatures.orange .PointsBox .pointThree{border: solid 1px var(--orange400);}


.DiscoverFeaturesV1{padding: 20px; border-radius: 8px;display: flex;padding-right: 0px; flex-direction: column; position: relative;min-height:462px;}
.DiscoverFeaturesV1 .HeadingBox{display: flex;flex-direction: row; justify-content: space-between; width:100%;padding-right: 10px;margin-bottom: 20px;}
.DiscoverFeaturesV1 .HeadingBox h4{font-size: 32px;font-weight: 600;line-height: 38px; }
.DiscoverFeaturesV1 .HeadingBox h5{font-size: 150px;font-weight: 860;line-height: 120px;}
.DiscoverFeaturesV1 .PointsBox{width: 100%; position:relative;}
.DiscoverFeaturesV1 .PointsBox .pointOne, .DiscoverFeaturesV1 .PointsBox .pointTwo, .DiscoverFeaturesV1 .PointsBox .pointThree{height:86px; border-radius: 8px;background: var(--white);padding: 15px;font-size: 18px;font-weight: 400;line-height: 26px;color: var(--black);}
.DiscoverFeaturesV1 .PointsBox .pointOne b, .DiscoverFeaturesV1 .PointsBox .pointTwo b, .DiscoverFeaturesV1 .PointsBox .pointThree b{font-size: 22px;font-weight: 500;line-height: 26px;color: var(--black);display: flex;}
.DiscoverFeaturesV1 .PointsBox .pointOne{width: 70%;position: absolute; right: -16px;display: flex;align-items: center;}
.DiscoverFeaturesV1 .PointsBox .pointTwo{width: 70%;position: absolute; right: 40px;top: 100px;display: flex;align-items: center;}
.DiscoverFeaturesV1 .PointsBox .pointThree{width: 70%;position: absolute; right: -16px;top:200px;display: flex;align-items: center;}
.DiscoverFeaturesV1.secondary{background: var(--secondary50);border: solid 1px var(--secondary400);}
.DiscoverFeaturesV1.secondary .HeadingBox h5{color: var(--secondary400); text-shadow: 1px 1px 0 var(--secondary300), -1px -1px 0 var(--secondary300), 1px -1px 0 var(--secondary300), -1px 1px 0 var(--secondary300), 1px 1px 0 var(--secondary300);}
.DiscoverFeaturesV1.secondary .PointsBox .pointOne, .DiscoverFeaturesV1.secondary .PointsBox .pointTwo, .DiscoverFeaturesV1.secondary .PointsBox .pointThree{border: solid 1px var(--secondary400);}
.DiscoverFeaturesV1.primary{background: var(--primary50);border: solid 1px var(--primary400);}
.DiscoverFeaturesV1.primary .HeadingBox h5{color: var(--primary400); text-shadow: 1px 1px 0 var(--primary300), -1px -1px 0 var(--primary300), 1px -1px 0 var(--primary300), -1px 1px 0 var(--primary300), 1px 1px 0 var(--primary300);}
.DiscoverFeaturesV1.primary .PointsBox .pointOne, .DiscoverFeaturesV1.primary .PointsBox .pointTwo, .DiscoverFeaturesV1.primary .PointsBox .pointThree{border: solid 1px var(--primary400);}
.DiscoverFeaturesV1.warning{background: var(--warning50);border: solid 1px var(--warning400);}
.DiscoverFeaturesV1.warning .HeadingBox h5{color: var(--warning400); text-shadow: 1px 1px 0 var(--warning300), -1px -1px 0 var(--warning300), 1px -1px 0 var(--warning300), -1px 1px 0 var(--warning300), 1px 1px 0 var(--warning300);}
.DiscoverFeaturesV1.warning .PointsBox .pointOne, .DiscoverFeaturesV1.warning .PointsBox .pointTwo, .DiscoverFeaturesV1.warning .PointsBox .pointThree{border: solid 1px var(--warning400);}
.DiscoverFeaturesV1.neutral{background: var(--neutral50);border: solid 1px var(--neutral400);}
.DiscoverFeaturesV1.neutral .HeadingBox h5{color: var(--neutral400); text-shadow: 1px 1px 0 var(--neutral300), -1px -1px 0 var(--neutral300), 1px -1px 0 var(--neutral300), -1px 1px 0 var(--neutral300), 1px 1px 0 var(--neutral300);}
.DiscoverFeaturesV1.neutral .PointsBox .pointOne, .DiscoverFeaturesV1.neutral .PointsBox .pointTwo, .DiscoverFeaturesV1.neutral .PointsBox .pointThree{border: solid 1px var(--neutral400);}
.DiscoverFeaturesV1.success{background: var(--success50);border: solid 1px var(--success400);}
.DiscoverFeaturesV1.success .HeadingBox h5{color: var(--success400); text-shadow: 1px 1px 0 var(--success300), -1px -1px 0 var(--success300), 1px -1px 0 var(--success300), -1px 1px 0 var(--success300), 1px 1px 0 var(--success300);}
.DiscoverFeaturesV1.success .PointsBox .pointOne, .DiscoverFeaturesV1.success .PointsBox .pointTwo, .DiscoverFeaturesV1.success .PointsBox .pointThree{border: solid 1px var(--success400);}
.DiscoverFeaturesV1.orange{background: var(--orange50);border: solid 1px var(--orange400);}
.DiscoverFeaturesV1.orange .HeadingBox h5{color: var(--orange400); text-shadow: 1px 1px 0 var(--orange300), -1px -1px 0 var(--orange300), 1px -1px 0 var(--orange300), -1px 1px 0 var(--orange300), 1px 1px 0 var(--orange300);}
.DiscoverFeaturesV1.orange .PointsBox .pointOne, .DiscoverFeaturesV1.orange .PointsBox .pointTwo, .DiscoverFeaturesV1.orange .PointsBox .pointThree{border: solid 1px var(--orange400);}
.DiscoverFeaturesV1.complementary{background: var(--complementary50);border: solid 1px var(--complementary400);}
.DiscoverFeaturesV1.complementary .HeadingBox h5{color: var(--complementary400); text-shadow: 1px 1px 0 var(--complementary300), -1px -1px 0 var(--complementary300), 1px -1px 0 var(--complementary300), -1px 1px 0 var(--complementary300), 1px 1px 0 var(--complementary300);}
.DiscoverFeaturesV1.complementary .PointsBox .pointOne, .DiscoverFeaturesV1.complementary .PointsBox .pointTwo, .DiscoverFeaturesV1.complementary .PointsBox .pointThree{border: solid 1px var(--complementary400);}

.SolutionBox{display: flex;flex-direction: column;align-items: center;}
.SolutionBox b{padding: 10px 10px; width: 80%;text-align: center;color: var(--white);font-size: 24px;font-weight:500;line-height: 28px;border-top-left-radius: 16px;border-top-right-radius: 16px;}
.SolutionBox .textBox{padding: 40px 16px; width:100%;box-shadow: 2px 2px 15px 0px rgba(0, 0, 0, 0.12);border-top-left-radius: 16px;border-top-right-radius: 16px;min-height: 400px;}
.SolutionBox .textBox ul{display: flex;gap: 16px;flex-direction: column;}
.SolutionBox .textBox ul li{display: flex;}
.SolutionBox .textBox ul li .bulletBrd{display: flex; width: 40px; height: 40px;border-radius: 30px; border: 1px solid var(--primary500);padding: 10px;margin-right: 12px;}
.SolutionBox .textBox ul li span{font-size: 18px; font-weight: 500; line-height: 20px; color: var(--neutral800);margin-left:8px;}
.SolutionBox.orange400 b{background: var(--orange400);}
.SolutionBox.orange400 .textBox{border-bottom: solid 4px var(--orange400);}
.SolutionBox.complementary400 b{background: var(--complementary400);} 
.SolutionBox.complementary400 .textBox{border-bottom: solid 4px var(--complementary400);}
.SolutionBox.primary500 b{background: var(--primary500);} 
.SolutionBox.primary500 .textBox{border-bottom: solid 4px var(--primary500);}
.ArrowBox{width: 100%;display: flex;justify-content: center;}
.ArrowBox .ArrowItem{width: 25%;position: relative;margin: 150px 0px;margin-right: -54px;}
.ArrowBox .ArrowItem .ArrowImg{}
.ArrowBox .ArrowItem .ArrowImg img{width: 100%;}
.ArrowBox .ArrowItem .ArerowHeading{position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);font-size: 28px; font-weight: 600;color: var(--black);text-align: center;line-height: 34px;width: 62%;}
.ArrowBox .ArrowItem .ArerowText{font-size: 16px;font-weight: 400;line-height: 20px;text-align: center;width: 82%;min-height: 80px;}
.ArrowBox .ArrowItem .ArerowText.top{position: absolute;top: -100px;}
.ArrowBox .ArrowItem .ArerowText.top::after{position: absolute; width: 2px;height: 24px;background: var(--black);content: '';left: 50%;bottom: -26px;transform: translate(-50%, 0%);}
.ArrowBox .ArrowItem .ArerowText.bottom{position: absolute;bottom:-100px;}
.ArrowBox .ArrowItem .ArerowText.bottom::after{position: absolute; width: 2px;height: 24px;background: var(--black);content: '';left: 50%;top: -26px;transform: translate(-50%, 0%);}

.AccelerateSales{background: #fff;border: 16px;overflow: hidden;border-radius: 8px;}
.AccelerateSales b{font-size: 28px;line-height: 34px;font-weight: 500;color: var(--black);padding:16px;width: 100%;display: block;text-align: center;line-height: normal;}
.AccelerateSales b span{border-radius: 6px; width: 45px;height: 45px;display: inline-block;text-align: center;line-height: 45px;}
.AccelerateSales .textBox{padding: 40px 16px; width:100%;min-height: 300px;}
.AccelerateSales .textBox span{display: block;font-size: 22px;font-weight: 500;line-height: 26px;padding-bottom: 4px;}
.AccelerateSales .textBox ul{display: flex;gap: 16px;flex-direction: column;}
.AccelerateSales .textBox ul li{display: flex;align-items: flex-start;}
.AccelerateSales .textBox ul li span{font-size: 16px; font-weight: 400;line-height: 20px; color: var(--neutral800);margin-left:8px;}
.AccelerateSales .textBox ul li span b{background: transparent;display: inline;font-size: 16px; font-weight: 500;padding: 0px;line-height: 20px;}
.AccelerateSales.primary {border: solid 1px var(--primary400);}
.AccelerateSales.primary b{background: var(--primary100);}
.AccelerateSales.primary b span{border:solid 1px var(--primary600);}
.AccelerateSales.orange {border: solid 1px var(--orange400);}
.AccelerateSales.orange b{background: var(--orange100);}
.AccelerateSales.orange b span{border:solid 1px var(--orange600);}
.AccelerateSales.warning {border: solid 1px var(--warning400);}
.AccelerateSales.warning b{background: var(--warning100);}
.AccelerateSales.warning b span{border:solid 1px var(--warning600);}
.AccelerateSales.secondary {border: solid 1px var(--secondary400);}
.AccelerateSales.secondary b{background: var(--secondary100);}
.AccelerateSales.secondary b span{border:solid 1px var(--secondary600);}
.AccelerateSales.complementary {border: solid 1px var(--complementary400);}
.AccelerateSales.complementary b{background: var(--complementary100);}
.AccelerateSales.complementary b span{border:solid 1px var(--complementary600);}
.AccelerateSales.success {border: solid 1px var(--success400);}
.AccelerateSales.success b{background: var(--success100);}
.AccelerateSales.success b span{border:solid 1px var(--success600);}

.mt80Minus{margin-top: -80px;}
.CTABox{background:  url(../src/assets/images/CTABg.png) var(--primary500) ;background-size: cover;}
.CTABox h2{color: var(--warning400) !important;}

.CallToAction {
  font-size: 18px; 
  font-weight: 400;
  line-height: 26px;color: var(--white);
}
.CallToAction h2{
  font-size: 32px; 
font-weight: 590;
line-height: 38px;
color: var(--primary50);margin: 0px;padding:0px ;
}
.blueOddEvenBox{position: relative;}
.blueOddEvenBox:before{background: var(--primary50);content: '';min-height: 100%;width: 50%;position: absolute;left: 0px;top: 0;z-index: -1;}
.blueOddEvenBox:after{background: var(--primary500);content: '';min-height: 100%;width: 50%;position: absolute;right: 0px;top: 0;z-index: -1;}
.blueOddEvenBox .leftBx{position: relative;}
.blueOddEvenBox .leftBx:before{background: var(--primary500);content: '';width: 24px;height: 20px;position: absolute;right: 0px;top: 25%;z-index: 1;}
.blueOddEvenBox .leftBx:after{background: var(--primary500);content: '';width: 24px;height: 20px;position: absolute;right: 0px;bottom: 25%;z-index: 1;}

.blueOddEvenBox .RightBx{position: relative;}
.blueOddEvenBox .RightBx:before{background: var(--primary50);content: '';width: 24px;height: 20px;position: absolute;left: 0px;top: 25%;z-index: 1;}
.blueOddEvenBox .RightBx:after{background: var(--primary50);content: '';width: 24px;height: 20px;position: absolute;left: 0px;bottom: 25%;z-index: 1;}
.blueOddEvenBox .OddTextBox{padding-right: 64px;}
.blueOddEvenBox .OddTextBox b{font-size: 28px;line-height: 34px;font-weight: 590;display: block;}
.blueOddEvenBox .OddTextBox .OddHeading{font-size: 40px;line-height: 48px;font-weight: 590;color: var(--neutral800);padding-bottom: 8px;border-bottom: solid 1px var(--primary500);}
.blueOddEvenBox .OddTextBox .OddHeading span{color: var(--primary500);}
.blueOddEvenBox .OddTextBox .OddContent ul{display: flex;gap: 16px;flex-direction: column;margin-top: 16px;}
.blueOddEvenBox .OddTextBox .OddContent ul li{display: flex;padding-bottom: 16px;}
.blueOddEvenBox .OddTextBox .OddContent ul li .bulletBrd{display: flex; width: 40px; height: 40px;border-radius: 30px; border: 1px solid var(--primary500);padding: 10px;margin-right: 12px;background: var(--white);}
.blueOddEvenBox .OddTextBox .OddContent ul li span{font-size: 22px; font-weight: 500; line-height: 26px; color: var(--black);margin-left:0px;}
.blueOddEvenBox .OddTextBox .OddContent ul li span p{font-size: 16px; font-weight: 400; line-height: 20px; color: var(--neutral800);margin-left:0px;}
.blueOddEvenBox .OddTextBox .OddContent ul li span b{display: block;font-size: 28px; font-weight: 510;line-height: 34px; color: var(--black);}

.blueOddEvenBox .EvenTextBox{padding-left: 64px;color: var(--white);}
.blueOddEvenBox .EvenTextBox b{font-size: 28px;line-height: 34px;font-weight: 590;display: block;}
.blueOddEvenBox .EvenTextBox .EvenHeading{font-size: 40px;line-height: 48px;font-weight: 590;color: var(--white);padding-bottom: 8px;border-bottom: solid 1px var(--warning500);}
.blueOddEvenBox .EvenTextBox .EvenHeading span{color: var(--warning500);}
.blueOddEvenBox .EvenTextBox .EvenContent ul{display: flex;gap: 16px;flex-direction: column;margin-top: 16px;}
.blueOddEvenBox .EvenTextBox .EvenContent ul li{display: flex;padding-bottom: 16px;}
.blueOddEvenBox .EvenTextBox .EvenContent ul li .bulletBrd{display: flex; width: 40px; height: 40px;border-radius: 30px; border: 1px solid var(--primary500);padding: 10px;margin-right: 12px;background: var(--white);}
.blueOddEvenBox .EvenTextBox .EvenContent ul li span{font-size: 22px; font-weight: 500; line-height: 26px; color: var(--white);margin-left:0px;}
.blueOddEvenBox .EvenTextBox .EvenContent ul li span p{font-size: 16px; font-weight: 400; line-height: 20px; color: var(--white);margin-left:0px;}
.blueOddEvenBox .EvenTextBox .EvenContent ul li span b{display: block;font-size: 28px; font-weight: 510;line-height: 34px;}

.blueOddEvenBoxRevse{position: relative;}
.blueOddEvenBoxRevse:before{background: var(--primary50);content: '';min-height: 100%;width: 50%;position: absolute;right: 0px;top: 0;z-index: -1;}
.blueOddEvenBoxRevse:after{background: var(--primary500);content: '';min-height: 100%;width: 50%;position: absolute;left: 0px;top: 0;z-index: -1;}
.blueOddEvenBoxRevse .leftBx{position: relative;}
.blueOddEvenBoxRevse .RightBx{position: relative;}

.TraditionalMethodsBox .headingGreen{background: var(--success400);padding: 12px; color: var(--white); font-size: 32px;line-height: 38px;font-weight: 500; text-align: right;width: 45%;}
.TraditionalMethodsBox .headingBlack{background: var(--black);padding: 12px; color: var(--white); font-size: 32px;line-height: 38px;font-weight: 500; text-align: center;width: 10%;}
.TraditionalMethodsBox .headingComplementary{background: var(--complementary400);padding: 12px; color: var(--white); font-size: 32px;line-height: 38px;font-weight: 500; text-align: left;width: 45%;}
.TraditionalMethodsBox .SubheadingGreen{background: var(--success50);padding: 12px 8px; color: var(--black); font-size: 22px;line-height: 26px;font-weight: 300;border-right: 2px solid var(--success400);border-left: 2px solid var(--success400); text-align: right;width: 45%;}
.TraditionalMethodsBox .SubheadingBlack{background: var(--neutral20);padding: 0px 16px; color: var(--black); font-size: 18px;line-height: 22px;font-weight: 400; text-align: center;border-top: 1px solid var(--black);border-bottom: 1px solid var(--black);width: 10%;display: flex;align-items: center;justify-content: center;}
.TraditionalMethodsBox .SubheadingComplementary{background: var(--complementary50);padding: 12px 8px; color: var(--black); font-size: 22px;line-height: 26px;font-weight: 300; text-align: left;border-right: 2px solid var(--complementary400);border-left: 2px solid var(--complementary400);width: 45%;}
.HomeProductImg{}
.py-90{padding-top: 90px; padding-bottom: 90px;}
.pt-90{padding-top: 90px;}
.pb-90{padding-bottom: 90px;}
.my-90{margin-top: 90px; margin-bottom: 90px;}
.my-90{margin-top: 90px;}
.my-90{margin-bottom: 90px;}
@media only screen and (min-width: 1920px) {
  .CatMenuDropBox {
    width: 1503px;
  }
  .CatMenuLast {
    width: 820px;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1920px) {
  .CatMenuDropBox {
    width: 1503px;
  }
  .CatMenuLast {
    width: 820px;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1440px) {
  .CatMenuDropBox {
    width: 1248px;
  }
  .CatMenuLast {
    width: 680px;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1365px) {
  
    .ZigZagImgBox .ImgItem{position: absolute;z-index: -1;left: -16px;top: -16px;}
    .ZigZagImgBox .ImgItemLeft{position: absolute;z-index: -1;right: -16px;top: -16px;}
}  
@media (max-width: 1279px) {
  .DiscoverFeatures .HeadingBox h4{font-size: 24px;line-height: 28px;}
  .DiscoverFeatures .HeadingBox h5{font-size: 124px;}
  .DiscoverFeatures .PointsBox .pointOne, .DiscoverFeatures .PointsBox .pointTwo, .DiscoverFeatures .PointsBox .pointThree{padding: 8px;font-size: 16px;line-height: 18px;}
  .DiscoverFeatures .PointsBox .pointOne b, .DiscoverFeatures .PointsBox .pointTwo b, .DiscoverFeatures .PointsBox .pointThree b{font-size: 18px;line-height: 20px;}
  .DiscoverFeatures .PointsBox .pointOne, .DiscoverFeatures .PointsBox .pointThree{width: 250px;}
  .DiscoverFeatures .PointsBox .pointTwo{width: 280px;}
  .CatMenuDropBox {
    width: 990px;
  }
  .CatMenuLast {
    width: 540px;
  }
  .whiteSmallCard{
    width: calc(calc(100% - 48px) / 3);
   }
  
   .colWidth-4{width: calc(calc(100% - 48px) / 3);}
   .colWidth-5{width: calc(calc(100% - 48px) / 3);}
   button.Btn.sm{padding: 8px;width: 162px;}
.headingContainer .heading{font-size: 40px;font-weight: 590;line-height: 46px;margin-bottom: 8px;}
.headingContainer .Subheading{font-size:22px;line-height: 24px;} 
.ZigZagSection{gap: 44px;}
.ZigZagText{font-size: 16px; font-weight: 400; line-height: 20px; color: var(--neutral800);}
.ZigZagText .grayText{padding-bottom: 16px;font-size: 20px; font-weight: 510; line-height: 24px;}
.ZigZagText .heading48{font-size: 34px;font-weight: 590;line-height: 40px;}
.ZigZagText .heading48 span.underLine{border-bottom:3px solid var(--warning400);}
.ZigZagText .Subhead{padding-top:8px;}
.ZigZagText .Subhead b{font-size: 22px; font-weight: 590; line-height: 28px;}
.ZigZagText .Subhead p{font-size: 16px; font-weight: 400; line-height: 20px;}
.ZigZagText .PointBox{padding-top: 16px;}
.ZigZagText .PointBox ul{display: flex;gap: 16px;} 
.ZigZagText .PointBox ul li .bulletBrd{width: 32px; height: 32px;padding: 6px;margin-right:6px;}
.ZigZagText .PointBox ul li span{font-size: 16px; font-weight: 400; line-height: 20px; color: var(--neutral800);}
.ZigZagText .PointBox ul li span b{display: block;font-size: 20px; font-weight: 510;line-height: 1;padding-bottom: 4px; color: var(--black);}
.SolutionBox .textBox {min-height: 250px;}
.SolutionBox b{font-size: 20px;padding: 8px 4px;}
.SolutionBox .textBox ul li span{font-size: 14px;line-height: 16px;}
}
@media only screen and (max-width: 768px) {
  .mt80Minus{margin-top: 40px;}
  .DiscoverFeatures{min-height: 420px;}
  .DiscoverFeatures .HeadingBox h4{font-size: 24px;line-height: 28px;}
  .DiscoverFeatures .HeadingBox h5{font-size: 124px;}
  .DiscoverFeatures .PointsBox .pointOne, .DiscoverFeatures .PointsBox .pointTwo, .DiscoverFeatures .PointsBox .pointThree{padding: 8px;font-size: 16px;line-height: 18px;}
  .DiscoverFeatures .PointsBox .pointOne b, .DiscoverFeatures .PointsBox .pointTwo b, .DiscoverFeatures .PointsBox .pointThree b{font-size: 18px;line-height: 20px;}
  .DiscoverFeatures .PointsBox .pointOne, .DiscoverFeatures .PointsBox .pointThree{width: 90%;min-height: 100px;}
  .DiscoverFeatures .PointsBox .pointTwo{width: 90%;min-height: 100px;top:140px;}
  .DiscoverFeatures .PointsBox .pointThree{top:260px;}
  .headingContainer .heading, .PageInfoBoxGry h2{font-size: 32px;}
  .colWidth-5{width: calc(calc(100% - 32px) / 2);}
  .colWidth-3{width: calc(calc(100% - 32px) / 3);}
  .colWidth-3m1{width: calc(calc(100% - 32px) / 2);}
  .colWidth-4{width: calc(calc(100% - 32px) / 2);}
  .insideBannerBx{position: relative;height: 300px; width: 100%;}
  .insideBannerInfo b{font-size: 24px;font-weight: 590;line-height: 28px;display: block;margin-bottom: 16px;}
  .insideBannerInfo h1{font-size: 24px;font-weight: 590;line-height: 28px;}
  .insideBannerInfo span{color: var(--warning400);}
  .insideBannerInfo h1 span.orange400{color: var(--orange400);}
  .insideBannerInfo p{font-size: 16px;font-weight: 400;line-height: 20px;margin-top: 8px;width: 70%;}
  .insideBannerInfo p span{color: var(--warning400);} 
  .insideBannerInfo button.HeaderBtn{border-radius: 4px;background: #2743A0;display: inline-block; padding: 6px 12px;margin-top: 8px;align-items: center;gap: 10px;text-decoration: none;font-weight: 400;font-size: 14px;}
  .insideBannerInfo button.HeaderBtn svg{height: 20px; width: 20px; margin-left: 8px; border: solid 1px #fff;border-radius: 50%;margin-bottom: 2px;}
  .roundZigZag{margin-bottom: 0px;}
  .roundZigZag .NumberBoxRight, .roundZigZag .NumberBoxLeft{height: 106px; width: 106px;border-width:6px;}
  .roundZigZag .NumberBoxRight span, .roundZigZag .NumberBoxLeft span{width: 80px; height: 80px;font-size: 44px;line-height: 80px;}
  .roundZigZag .TextBox{font-size: 16px;line-height: 20px;}
  .roundZigZag .TextBox b{font-size: 24px;line-height: 22px;}
  .SolutionBox .textBox {min-height: auto;}
  .SolutionBox b{font-size: 18px;padding: 8px;}

  .ArrowBox{width: 100%;display: flex;justify-content: center;flex-direction: column; gap: 30px;}
.ArrowBox .ArrowItem{width: 100%;position: relative;margin: 0px 0px;margin-right: 0px;height: 262px;display: flex;align-items: center;flex-direction: column;justify-content:center;}
.ArrowBox .ArrowItem .ArrowImg {position: absolute;width: 100%;}
.ArrowBox .ArrowItem .ArerowHeading{font-size: 16px;width: 80%;margin: 0;position: relative;transform: none;left: auto;top:auto;}
.ArrowBox .ArrowItem .ArerowText{width: 80%;}
.ArrowBox .ArrowItem .ArerowText.bottom{position: relative;bottom: auto;}
.ArrowBox .ArrowItem .ArerowText.top{position: relative;top: auto;}
.ArrowBox .ArrowItem .ArerowText.bottom::after, .ArrowBox .ArrowItem .ArerowText.top::after{display: none;}
.py-90{padding-top: 32px; padding-bottom: 32px;}
.pt-90{padding-top: 32px;}
.pb-90{padding-bottom: 32px;}
.my-90{margin-top: 32px; margin-bottom: 32px;}
.my-90{margin-top: 32px;}
.my-90{margin-bottom: 32px;}
}
@media only screen and (max-width: 480px) {
  .txt22{font-size: 22px !important;line-height: 28px !important;}
.txt24{font-size: 16px !important;line-height: 20px !important;}
.txt32{font-size: 32px !important;line-height: 38px !important;}
.txt40{font-size: 22px !important;line-height: 26px !important;}
  .mt80Minus{margin-top: 40px;}
  .txt22{font-size: 16px !important;line-height: 20px !important;}
  .DiscoverFeatures{min-height: 420px;flex-direction: column;padding:8px 0px 8px 8px }
  .DiscoverFeatures .HeadingBox{width: 100%;flex-direction: row;}
  .DiscoverFeatures .PointsBox {width: 100%;position: relative;}
  .DiscoverFeatures .HeadingBox h4{font-size: 22px;line-height: 28px;}
  .DiscoverFeatures .HeadingBox h5{font-size: 72px;line-height: 90px;}
  .DiscoverFeatures .PointsBox .pointOne, .DiscoverFeatures .PointsBox .pointTwo, .DiscoverFeatures .PointsBox .pointThree{padding: 8px;font-size: 12px;line-height: 14px;}
  .DiscoverFeatures .PointsBox .pointOne b, .DiscoverFeatures .PointsBox .pointTwo b, .DiscoverFeatures .PointsBox .pointThree b{font-size: 16px;line-height: 18px;}
  .DiscoverFeatures .PointsBox .pointOne, .DiscoverFeatures .PointsBox .pointThree{width: 90%;min-height: 80px;}
  .DiscoverFeatures .PointsBox .pointTwo{width: 90%;min-height: 80px;top:114px;}
  .DiscoverFeatures .PointsBox .pointThree{top:210px;}
button.Btn{border-radius: 8px;display: inline-block; padding:4px 8px;align-items: center;gap: 10px;text-decoration: none;font-weight: 7510;font-size: 13.5px;}
button.Btn svg{height: 24px; width: 24px; margin-left: 8px; border: solid 1px #fff;border-radius: 50%;}
.colWidth-5{width: calc(calc(100% - 16px) / 2);}
.colWidth-3{width: calc(calc(100% - 16px) / 2);}
.colWidth-3m1{width: calc(calc(100% - 0px) / 1);}
.colWidth-4{width: calc(calc(100% - 16px) / 2);}
.colWidth-3m1V1{width: calc(calc(100% - 0px) / 1);}
.colWidthD1M2{width: calc(calc(100% - 8px) / 2);}
.insideBannerBx{position: relative;height: 300px; width: 100%;}
.insideBannerInfo b{font-size: 24px;font-weight: 590;line-height: 28px;display: block;margin-bottom: 16px;}
.insideBannerInfo h1{font-size: 24px;font-weight: 590;line-height: 28px;}
.insideBannerInfo span{color: var(--warning400);}
.insideBannerInfo h1 span.orange400{color: var(--orange400);}
.insideBannerInfo p{font-size: 16px;font-weight: 400;line-height: 20px;margin-top: 8px;width: 70%;}
.insideBannerInfo p span{color: var(--warning400);} 
.insideBannerInfo button.HeaderBtn{border-radius: 4px;background: #2743A0;display: inline-block; padding: 6px 12px;margin-top: 8px;align-items: center;gap: 10px;text-decoration: none;font-weight: 400;font-size: 14px;}
.insideBannerInfo button.HeaderBtn svg{height: 20px; width: 20px; margin-left: 8px; border: solid 1px #fff;border-radius: 50%;margin-bottom: 2px;}

.PageInfoBoxGry small{font-size: 18px; font-weight: 590;line-height: 22px;margin-bottom: 4px;}
.PageInfoBoxGry h2{font-size: 28px; font-weight: 510;line-height: 34px;}
.PageInfoBoxGry p{font-size: 14px;font-style: normal;font-weight: 400;line-height: 16px;margin-bottom: 8px;}
.whiteSmallCard{width: calc(calc(100% - 24px) / 2); padding: 8px; gap: 8px;}
.whiteSmallCard img{height: 64px;}
.whiteSmallCard b{font-size: 14px;font-weight: 700;line-height: 16px;}
.whiteSmallCard p{font-size: 12px;font-weight: 400;line-height: 14px;}
.headingContainer {padding-bottom: 20px;}
.headingContainer .heading{font-size: 28px;font-weight: 590;line-height: 34px;padding-bottom: 0px;margin-bottom: 0px;}
.headingContainer .Subheading{font-size: 16px;font-weight: 400;line-height: 20px;margin-top: 4px;} 
.ZigZagSection{gap:0px;padding:32px 0px;border-bottom: 1px solid var(--neutral300);}
.ZigZagImgBox{padding: 30px;}
.ZigZagImgBox img{border-radius: 20px;border: 4px solid var(--white);background: var(--white);box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.50);}
.ZigZagImgBox .ImgItem img, .ZigZagImgBox .ImgItemLeft img{display: none;}
.ZigZagText .grayText{font-size: 16px;padding-bottom: 10px;}
.ZigZagText .blueBgHeading{padding:8px 8px;font-size: 16px; font-weight: 510; line-height: 16px;}
.ZigZagText .BlueText{font-size: 16px; font-weight: 600; line-height: 20px;padding-bottom: 10px;}
.ZigZagText .heading48 {font-size: 24px; line-height: 28px;}
.ZigZagText .heading48 span.underLine{border-bottom:2px solid var(--warning400);}
.ZigZagText .Subhead {gap:4px;}
.ZigZagText .Subhead b{font-size: 16px; font-weight: 510px;line-height: 20px;}
.ZigZagText .Subhead p{font-size: 14px; font-weight: 400;line-height: 16px;} 
.ZigZagText .PointBox i{font-size: 16px;line-height: 20px;}
.ZigZagText .PointBox ul li .bulletBrd{width: 20px;height: 20px;padding: 4px;border-width: .5px;margin-right: 4px;}
.ZigZagText .PointBox ul li span{font-size: 14px; line-height: 16px;}
.ZigZagText .PointBox ul li span b{font-size: 16px; line-height: 20px;line-height: 1;}
.whiteGrayBox{padding: 8px;gap:4px;}
.whiteGrayBox .iconBox{width:64px;height: 64px;}
.whiteGrayBox .TextBox{font-size: 12px;line-height: 14px;}
.whiteGrayBox .TextBox b{font-size: 14px;line-height: 16px;padding-bottom: 4px;}
.grayWhiteBox{padding: 8px;gap:4px;}
.grayWhiteBox .iconBox{width:64px;height: 64px;}
.grayWhiteBox .TextBox{font-size: 12px;line-height: 14px;}
.grayWhiteBox .TextBox b{font-size: 14px;line-height: 16px;padding-bottom: 4px;}
.grayWhiteBoxV1 b{font-size: 18px;line-height: 22px;padding: 8px 12px;}
.grayWhiteBoxV1 .TextBox{padding: 8px;}
.grayWhiteBoxV1 .TextBox .iconBox{width: 64px;height: 64px;}
.grayWhiteBoxV1 .TextBox .textItem {font-size: 16px; float: left; padding-left: 12px; display: flex; width: 82%; flex-direction: column; justify-content: center;padding-right: 0px;}
.grayWhiteBoxV1 .TextBox .textItem b{font-size: 14px;line-height: 16px;font-weight: 500;padding-bottom: 4px;}
.grayWhiteBoxV1 .TextBox .textItem p{font-size: 12px;line-height: 14px;}

.CallToAction { font-size: 12px; font-weight: 400; line-height: 14px;}
.CallToAction h2{ color: var(--warning400);  font-size: 22px;  font-weight: 590; line-height: 26px;}
.mobileGray{border: 1px solid var(--neutral200);background: var(--neutral20);padding: 8px;gap: 8px;}


.ScoreBox{font-size: 14px;line-height: 16px;margin-top: 20px;}
.ScoreBox h3{font-size: 16px;}
.ScoreBox h3 span{font-size: 24px;}
.ScoreBox .LearnMore, .ScoreBox .ContactUs{padding: 8px;font-size: 14px;line-height: 20px;}
.ScoreBox .ScoreStatusBox h4{font-size: 22px;width: 60%;margin: 10px auto;line-height: 24px;}
.ScoreBox .ScoreItem{position: relative;}
.ScoreBox .ScoreItem span{position: absolute;font-size: 44px;font-weight: 700;width: 100%;text-align: center;bottom:10%; left: 0;}

.roundZigZag{margin-bottom: 0px;}
.roundZigZag .NumberBoxRight, .roundZigZag .NumberBoxLeft{height: 80px; width: 80px;border-width:6px;}
.roundZigZag .NumberBoxRight span, .roundZigZag .NumberBoxLeft span{width: 60px; height: 60px;font-size: 24px;line-height: 60px;}
.roundZigZag .TextBox{font-size: 14px;line-height: 16px;}
.roundZigZag .TextBox b{font-size: 16px;line-height: 18px;}
.SolutionBox .textBox {min-height: auto;}
.SolutionBox b{font-size: 18px;padding: 8px;}
.SolutionBox .textBox ul li span{font-size: 14px;line-height: 16px;}
.SolutionBox .textBox ul li{align-items: center;}

.ArrowBox{width: 100%;display: flex;justify-content: center;flex-direction: column;gap: 0px;}
.ArrowBox .ArrowItem{width: 100%;position: relative;margin: 0px 0px;margin-right: 0px;height:auto;display: flex;align-items: center;flex-direction: column;justify-content:center;height: 208px;}
.ArrowBox .ArrowItem .ArrowImg {position: absolute;width: 100%;}
.ArrowBox .ArrowItem .ArerowHeading{font-size: 16px;width: 80%;margin: 0;position: relative;transform: none;left: auto;top:auto;}
.ArrowBox .ArrowItem .ArerowText{width: 80%;font-size: 12px;line-height: 14px;}
.ArrowBox .ArrowItem .ArerowText.bottom{position: relative;bottom: auto;min-height: 44px; }
.ArrowBox .ArrowItem .ArerowText.top{position: relative;top: auto;}
.ArrowBox .ArrowItem .ArerowText.bottom::after, .ArrowBox .ArrowItem .ArerowText.top::after{display: none;}
.AccelerateSales b{font-size: 18px;}
.AccelerateSales b span{width: 32px;height: 32px;line-height: 32px;}
.AccelerateSales .textBox{padding:16px;font-size: 12px;line-height: 16px;}
.AccelerateSales .textBox span{font-size: 14px;line-height: 18px;}
.DiscoverFeaturesV1 .HeadingBox h4{font-size: 22px;line-height: 26px;}
.DiscoverFeaturesV1 .HeadingBox h5{font-size: 72px;line-height: 86px;}
.DiscoverFeaturesV1 .PointsBox .pointOne{width: 94%;position: absolute; right: -16px;height: 78px;}
.DiscoverFeaturesV1 .PointsBox .pointTwo{width: 94%;position: absolute; right: 30px;top: 100px;height: 78px;}
.DiscoverFeaturesV1 .PointsBox .pointThree{width: 94%;position: absolute; right: -16px;top:200px;height: 78px;}
.DiscoverFeaturesV1 .PointsBox .pointOne b, .DiscoverFeaturesV1 .PointsBox .pointTwo b, .DiscoverFeaturesV1 .PointsBox .pointThree b{font-size: 16px;line-height: 20px;}
.DiscoverFeaturesV1 .PointsBox .pointOne, .DiscoverFeaturesV1 .PointsBox .pointTwo, .DiscoverFeaturesV1 .PointsBox .pointThree{padding: 8px;}
.blueOddEvenBox:before,.blueOddEvenBox:after{display: none;}
.blueOddEvenBox .leftBx{background: var(--primary50);}
.blueOddEvenBox .RightBx{background: var(--primary500);}
.blueOddEvenBox .OddTextBox, .blueOddEvenBox .EvenTextBox{padding: 20px 12px;}
.blueOddEvenBox .OddTextBox .OddHeading, .blueOddEvenBox .EvenTextBox .EvenHeading{font-size: 22px;line-height: 26px;}
.blueOddEvenBox .OddTextBox .OddContent ul li .bulletBrd, .blueOddEvenBox .EvenTextBox .EvenContent ul li .bulletBrd{width: 30px;height: 30px;padding: 7px;border-width: .5px;margin-right: 4px;}
.blueOddEvenBox .OddTextBox .OddContent ul li span, .blueOddEvenBox .EvenTextBox .EvenContent ul li span{font-size: 12px; line-height: 14px;}
.blueOddEvenBox .OddTextBox .OddContent ul li span b, .blueOddEvenBox .EvenTextBox .EvenContent ul li span b{font-size: 16px; line-height: 20px;line-height: 1;}
.blueOddEvenBox .leftBx:before{width:20px; height: 16px; position: absolute; right: auto; bottom: 0%;left: 25%; top: auto;}
.blueOddEvenBox .leftBx:after{width:20px; height: 16px; position: absolute; right: 25%; bottom: 0%;left: auto; top: auto;}
.blueOddEvenBox .RightBx:before{width:20px; height: 16px; position: absolute; right: auto; bottom: auto;left: 25%; top: 0;}
.blueOddEvenBox .RightBx:after{width:20px; height: 16px; position: absolute; right: 25%; bottom:auto;left: auto; top: 0;}

.TraditionalMethodsBox .headingGreen{background: var(--success400);padding: 4px; color: var(--white); font-size: 18px;line-height: 22px;font-weight: 500; text-align: right;width: 40%;}
.TraditionalMethodsBox .headingBlack{display: flex;align-items: center;justify-content: center; background: var(--black);padding: 4px; color: var(--white); font-size: 18px;line-height: 22px;font-weight: 500; text-align: center;width: 20%;}
.TraditionalMethodsBox .headingComplementary{background: var(--complementary400);padding: 4px; color: var(--white); font-size: 18px;line-height: 22px;font-weight: 500; text-align: left;width: 40%;}
.TraditionalMethodsBox .SubheadingGreen{background: var(--success50);padding: 4px; color: var(--black); font-size: 13px;line-height: 16px;font-weight: 300;border-right: 2px solid var(--success400);border-left: 2px solid var(--success400); text-align: right;width: 40%;}
.TraditionalMethodsBox .SubheadingBlack{background: var(--neutral20);padding: 4px; color: var(--black); font-size: 12px;line-height: 16px;font-weight: 400; text-align: center;border-top: 1px solid var(--black);border-bottom: 1px solid var(--black);width: 20%;display: flex;align-items: center;justify-content: center;}
.TraditionalMethodsBox .SubheadingComplementary{background: var(--complementary50);padding: 4px; color: var(--black); font-size: 13px;line-height: 16px;font-weight: 300; text-align: left;border-right: 2px solid var(--complementary400);border-left: 2px solid var(--complementary400);width: 40%;}


}


.sliders {
  margin:0 20px;
  overflow:"hidden";
  padding:2rem 0;width: 160px;height: 100px;
  display: flex;justify-content: center;align-items: center;
}

.sliders img {
  height: 90px;
  border-radius:10px;
}

.react-multi-carousel-list {
padding: 0rem 0 2rem 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;